/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/scss/functions'
@import '.~bootstrap/scss/mixins'

@import '~@ng-select/ng-select/themes/default.theme.css'

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css'
	
body
	background-color: #f0f0f0
